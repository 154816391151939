.dashboard-side-bar {
  border-radius: 8px;
  width: 200px;
  font-size: 0.875rem !important;
  font-weight: 600;
  text-transform: uppercase;
  /* width: 11.5rem; */
  height: 100%;
  background: white;
  position: fixed !important;
  z-index: 11;
  transition: transform 0.3s ease-out;
  top: 0;
}

.side-bar-container {
  background: hsl(0, 0%, 100%);
  /* background-color: hsl(206, 73%, 10%); */
  /* color: #F4F4F5; */
  /* width: 180px; */
  width: 100%;
  /* width: 14.25vw; */
  position: fixed;
  /* height: 100%; */
  height: 64.6%;
  z-index: 4;
  overflow: auto;
  margin-top: 1rem;
  /* margin-top: 15vh;
    height: 64vh; */
}

.expansion-panel-header {
  padding: 1rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* color: hsl(223, 22%, 72%); */
  color: hsl(223, 22%, 42%);
  /* color: #F4F4F5; */
}

.expansion-panel-header:hover,
.expansion-panel-item:hover {
  background: hsl(0, 0%, 90%);
  /* background-color: hsl(206, 73%, 20%); */
}

.expansion-panel-item {
  padding: 1rem 1rem 1rem 1rem;
  cursor: pointer;
  font-size: 0.9rem;
}

a {
  color: hsl(223, 22%, 72%);
  /* color: hsl(240, 6%, 80%); */
}

.arrow {
  font-size: 24px;
  /* color: hsl(223, 22%, 72%); */
  color: hsl(223, 22%, 42%);
  /* color: #F4F4F5; */
}

.hide-expansion-panel {
  height: 0;
  transition: height 0.3s;
  overflow-y: auto;
}

.show-expansion-panel {
  height: 96px;
  /* background: hsl(0, 0%, 95%); */
  /* background-color: hsl(206, 73%, 25%); */
}

.show-expansion-panel-recon {
  /* height: 256px; */
  height: 370px;
}

.show-expansion-panel-settle {
  height: 155px;
}

.athensGrayColor {
  color: #f4f4f5;
}

.cadetBlueColor {
  color: hsl(223, 22%, 42%) !important;
}

.fireFlyColor {
  color: hsl(211, 54%, 11%);
}

.downRiverBackGround {
  background-color: hsl(206, 73%, 20%);
}

.active-header, .sidebar-active-tab {
  background:  hsl(240deg 50% 97%);
  color: hsl(211, 54%, 11%);
  font-weight: bold;
}

.logo {
  /* width: 30%; */
  /* padding: 1rem 1rem 1rem 2rem; */
  /* margin-right: 2rem; */
  padding: 1rem 4rem 2rem 4rem;
  /* padding: 1rem 2.45rem 1rem 1rem; */
  width: 3.5rem;
  z-index: 5;
  position: fixed;
  top: 0;
  left: 0;
  background: white;
  height: 4rem;
}

.dashboard-image {
  /* width: 60%; */
  /* padding: 1rem 1rem 1rem 1rem; */
  padding: 1.5rem 2.45rem 1rem 1rem;
  /* margin-top: 2rem; */
  width: 8.05rem;
  z-index: 5;
  position: fixed;
  bottom: 0;
  left: 0;
  background: white;
  height: 15%;
}

.side-bar-container::-webkit-scrollbar {
  width: 5px;
}

.side-bar-container::-webkit-scrollbar-thumb {
  background: #888;
}

.open {
  transform: translateX(0);
}

.close {
  transform: translateX(-100%);
}

.dashboardSideBarHeaderContainer 
.hide-expansion-panel::-webkit-scrollbar {
  width: 4px;
}

.dashboardSideBarHeaderContainer 
.hide-expansion-panel::-webkit-scrollbar-track {
  background: #f1f1f1; 
}

.dashboardSideBarHeaderContainer 
.hide-expansion-panel::-webkit-scrollbar-thumb {
  background: rgb(199, 195, 195); 
}

/* .dashboardSideBarHeaderContainer 
.hide-expansion-panel::-webkit-scrollbar-thumb:hover {
  background: #555; 
} */

@media only screen and (min-width: 720px) {
  .dashboard-side-bar {
    transform: translateX(0);
  }
}