.text-center{
  text-align: center;
}
table {
  width: 100%;
  border-collapse: collapse;
  /* color: #606266; */
  color: hsl(222, 19%, 54%);
  background: white;
}

th,
td {
  text-align: center;
  /* border: #ebeef5 1px solid; */
  padding: 8px 2px !important;
  border-bottom: hsl(240, 54%, 97%) solid 4px;
}

th {
  background: hsl(211, 54%, 11%) !important;
  color: hsl(223, 22%, 72%);
  box-shadow: none !important;
  border-bottom: none;
  padding-top: 0.875rem !important;
  padding-bottom: 0.875rem !important;
  min-width: 100px;
  font-size: 14px;
}

.table-container {
  max-height: 60vh;
}

.thead-th {
  z-index: 1;
  box-shadow: 2px 0px 2px hsl(211, 54%, 11%) !important;
  position: sticky !important;
  top: 0;
}

.overFlowAuto {
  overflow: auto;
}

.sticky-column {
  position: sticky !important;
  min-width: 110px;
  /* white-space: nowrap; */
  background: white;
  left: 0;
  /* box-shadow: 0 2px 2px 0 #dfe1f3, 0 2px 4px 0 #c6c7d7; */
  box-shadow: 2px 0px 2px #dfe1f3;
  z-index: 2;
}

.sticky-columnUpper {
  position: sticky !important;
  min-width: 110px;
  /* white-space: nowrap; */
  background: white;
  left: 0;
  /* box-shadow: 0 2px 2px 0 #dfe1f3, 0 2px 4px 0 #c6c7d7; */
  box-shadow: 2px 0px 2px #dfe1f3;
  /* z-index: 3; */
  z-index: 3 !important;
}

.loader {
  border: 4px solid #f3f3f3;
  border-radius: 50%;
  border-top: 4px solid #3498db;
  width: 40px;
  height: 40px;
  -webkit-animation: spin 1s linear infinite; /* Safari */
  animation: spin 1s linear infinite;
}

.date-time{
  min-width: 110px;
}

.invoice_params{
  min-width: 120px;
}

.invoice_no_wrapper{
  min-width: 120px;
/* display: flex;
flex-direction: column; */
}

.invoice_flag_info{
display: flex;
justify-content: space-between;
align-items: center;
}

.invoice_flag_badge{
color: #fff;
border-radius: 4px;
padding: 0.25rem 0.5rem;
font-size: 12px;
font-weight: bold;
font-stretch: normal;
font-style: normal;
line-height: 1.2;
letter-spacing: normal;
text-align: center;
}

.invoice_badge_blue{
background-color: #3498db;
}

.invoice_badge_green{
background-color: #00ad2d;
}

.invoice_number{
  margin-top: 0.5rem;
}

.info-icon-wrapper{
  display: flex;
  justify-content: center;
  align-items: center;
}

.info-icon{
  width: 1.4rem;
  height: 1.4rem;
  object-fit: contain;
  cursor: pointer;
}

.info-icon:active{
  opacity: 0.5;
}

/* Safari */
@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.margin-left-30vw {
  margin-left: 30vw;
}

.border-right-none {
  border-right: none;
}

.no-data-found {
  /* margin-left: 20vw; */
  padding: 1rem;
  /* color: hsl(3, 79%, 45%); */
  color: hsl(206, 73%, 10%);
}

.edit-icon {
  cursor: pointer;
}

.is-reviewed {
  color: green;
}

.is-reviewed-yellow {
  color: blue;
}

.is-reviewed-black {
  color: black;
}

button.makeButton.makeFireFlyButton:disabled {
  cursor: default;
  background: gray;
}

.makeRedButton{
  background-color: red;
  color: white;
}

.button-disabled{
  opacity: 0.6;
  cursor : not-allowed !important;
}

input:disabled {
  /* background: lightgray; */
}

i.download-button-icon:before {
  content: url("../../../Images/download_icon_green.svg");
  position: relative;
  padding-right: 6px;
}

button.makeButton.makeFireFlyButton.buttonDownload {
  background: white;
  color: black;
  width: 110px;
  border: 1.5px solid black;
  font-weight: normal;
  padding: 9px 5px;
}

.hideFileDiv {
  padding: 5px 10px;
  background: #00ad2d;
  border: 1px solid #00ad2d;
  position: relative;
  color: #fff;
  border-radius: 2px;
  text-align: center;
  float: left;
  cursor: pointer;
}
.hideFile {
  position: absolute;
  z-index: 1000;
  opacity: 0;
  cursor: pointer;
  right: 0;
  top: 0;
  height: 100%;
  font-size: 24px;
  width: 100%;
}
.fa-info-circle{
  cursor: pointer;  
}
.info-card{
  width: 202px;
  height: 84px;
  padding: 12px;
  border-radius: 6px;
  box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
  background-color: #fff3e0;
  display: none;
  position:absolute;
  bottom:3rem;
  left:2rem;
}
.info-card table{
  background-color:inherit;  
}
.info-card table tr td,.info-card table tr th{
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: normal;
  color: #6d3a00;
  border:none;
  text-align: right;
}
.info-card table tr th{
  font-weight: bolder;
  background-color: inherit !important;
  text-align: left;
}

.download_bill_btn{
  border: 1px solid transparent;
  border-radius : 3px;
  padding : 0.5rem 0.875rem;
  font-size: 1rem;
  margin: auto;
  cursor: pointer;
  opacity: 1;
  transform: scale(1);
  transition : all 0.25s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.download_bill_btn:hover{
  border: 1px solid rgba(0, 0, 0, 0.25);
}

.download_bill_btn:active{
  opacity: 0.75;
  transform: scale(0.95);
}

.disabled-pointer{
  cursor: not-allowed;
  opacity: 0.5;
}

.disabled-pointer:hover{
  border-color: transparent;
}

.do-amount-wrapper{
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}

.choose-file-wrapper{
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.choose-file-label-do{
  white-space: nowrap;
  font-size: 0.875rem;
}

.choose-file-btn-do{
  white-space: nowrap;
  padding: 0.5rem;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  cursor: pointer;
}

.upload-btn-do{
  color: #fff;
  background-color:#0072dd;
  white-space: nowrap;
  padding: 0.5rem;
  border: 0;
  border-radius: 3px;
  display: flex;
  gap: 0.5rem;
}

.do-amount-action-wrapper{
  display: flex;
  align-items: center;
  gap: 0.5rem;
  padding: 0 0.5rem;
}

.do-amount-action_download-file{
  color: #fff;
  background-color:#00a126;
  white-space: nowrap;
  padding: 0.5rem;
  border: 0;
  border-radius: 3px;
  display: flex;
  gap: 0.5rem;
  cursor: pointer;
}

.do-amount-btn-edit, .do-amount-btn-save{
  white-space: nowrap;
  padding: 0.5rem 1.6rem;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 3px;
  cursor: pointer;
  opacity: 1;
}

.do-amount-btn-edit:disabled, .do-amount-btn-save:disabled{
  opacity: 0.5;
  cursor: not-allowed;
}

.do-amount-btn-save{
  background-color:#0072dd;
  color: #fff;
  border-color: transparent;
}

.do-amt-input{
  height: 2rem;
  margin : 0 0.5rem;
  padding: 0.2rem;
  border-radius: 3px;
  border-color: rgba(0, 0, 0, 0.2);
}

.do-amt-input:focus{
  outline: none;
  border-color: rgba(0, 0, 0, 0.3);
}

.scrollerVariantOne::-webkit-scrollbar{ 
  width : 6px; 
  height: 6px;
}

.scrollerVariantOne::-webkit-scrollbar-thumb{
  border-radius: 4px;
  background-color : rgb(0 0 0 / 15%);
}

.bill-btn-group{
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  gap: 0.4rem;
  margin-left: 4px;
  margin-right: 4px;
}

.btn-bg-black{
  background-color: hsl(206, 73%, 20%) !important;
}

.bill-btn{
 color: #fff;
 white-space: nowrap;
 padding: 0.5rem 0.875rem;
 border-radius: 2px;
 border: 0;
 cursor: pointer;
 font-size: 1rem;
 opacity: 1;
 transition: all 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.bill-btn:active{
  opacity: 0.75;
}

.cursor-pointer{
  cursor: pointer !important;
}

.status-tag{
  border-radius: 3px;
  font-size: 0.875rem;
  color: #fff;
  padding: 0.5rem;
  width: 80px;
  margin: auto;
  cursor: not-allowed;
  border: 0;
}

.cursor-pointer{
  cursor:  pointer !important;
}

.status-tag-green{
  background-color: #00a126;
}

.status-tag-blue{
  background-color: #0072dd;
}

.status-tag-inactive{
  background-color: #aaa;
}

input{
  min-width: 70px;
}