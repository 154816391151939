.inputContainer {
	height: 48px;
	/* height: 42px !important;
    width: 130px;
	box-sizing: border-box;
    border-radius: 5px; */
	/* height: 40px; */
	/* border-radius: 10px; */
	border: solid 1px #d0d0d0;
	/* background-color: rgba(255, 255, 255, 0.36); */
	background: white;
	position: relative;
	padding: 0 8px;
	margin-right: 4px;
}
.inputTitle {
	font-size: 14px;
	position: absolute;
	transform-origin: top left;
	transform: translate(0, 16px) scale(1);
	transition: all 0.1s ease-in-out;
	/* color: #9b9b9b; */
	color: #606266;
}
.inputContainer.active .inputTitle {
	transform: translate(0, 4px) scale(0.75);
}
.mainInput {
	margin: 16px 0 10px;
	width: 90%;
	max-width: 300px;
	height: 16px;
	font-size: 14px;
	color: #000000;
	padding-top: 6px;
	border: none;
	outline: none;
	background-color: rgba(255, 255, 255, 0.36);
}

.error {
	border: solid 1px #ff4355;
}

.helperText {
	position: absolute;
	z-index: 10;
	font-size: 12px;
	top: 50px;
	color: #ff4355;
}
