.toast-wrapper{
	display: flex;
	align-items: center;
	gap: 0.5rem;
}
.toast-icon{
	display: grid;
	place-content: center;
	color: hsl(140, 100%, 30%);
	background-color: #fff;
	border-radius: 50%;
}

.toast-msg{
	flex: 1 1 auto;
}

#snackbar {
	visibility: hidden;
	min-width: 250px;
	margin-left: -125px;
	/* background-color: #333; */
	color: #fff;
	text-align: center;
	border-radius: 5px;
	padding: 16px;
	position: fixed;
	z-index: 4000;
	left: 50%;
	bottom: 30px;
	font-size: 17px;
	max-width: 300px;
	text-transform: capitalize;
	font-weight: 500;
}

#snackbar.show {
	visibility: visible;
	-webkit-animation: fadein 0.5s, fadeout 0.5s 2.5s;
	animation: fadein 0.5s, fadeout 0.5s 2.5s;
}

@-webkit-keyframes fadein {
	from {
		bottom: 0;
		opacity: 0;
	}
	to {
		bottom: 30px;
		opacity: 1;
	}
}

@keyframes fadein {
	from {
		bottom: 0;
		opacity: 0;
	}
	to {
		bottom: 30px;
		opacity: 1;
	}
}

@-webkit-keyframes fadeout {
	from {
		bottom: 30px;
		opacity: 1;
	}
	to {
		bottom: 0;
		opacity: 0;
	}
}

@keyframes fadeout {
	from {
		bottom: 30px;
		opacity: 1;
	}
	to {
		bottom: 0;
		opacity: 0;
	}
}

.success {
	background: hsl(140, 100%, 30%);
}

.toast-success{
	color: hsl(140, 100%, 30%);
}

.error{
	background: hsl(3, 79%, 45%);
}

.toast-error {
	color: hsl(3, 79%, 45%);
}