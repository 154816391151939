.check-box-checked {
    width: 1.2rem !important;
    height: 1rem;
    padding: 0.4rem;
    border-radius: 3px;
    border: hsl(206, 73%, 20%) solid 1px;
    background-color: hsl(206, 73%, 20%);
    cursor: pointer;
    padding-bottom: 0.7rem;
    padding-top: 0.1rem;
}

.check-box-unchecked {
    width: 1.2rem !important;
    height: 1rem;
    padding: 0.4rem;
    border-radius: 3px;
    border: hsl(206, 73%, 20%) solid 1px;
    cursor: pointer;
}

.check-mark {
    color: white;
    font-size: 1.5rem;
}

.radio {
    border-radius: 50%;
}

.white {
    background: white;
}