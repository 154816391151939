.mui-2-dropdown-check-list-garage-select {
	height: 48px;
	border-radius: 3px;
	border: solid 1px #d0d0d0;
	background-color: white;
	/* background-color: rgba(255, 255, 255, 0.36); */
	position: relative;
	display: flex;
	min-width: 290px;
	/* border-radius: 10px; */
}

.mui-2-dropdown-check-list-garage-select .anchorInput {
	padding-left: 5px;
	border: none;
	width: 100%;
	background: transparent;
	font-size: 16px;
	font-family: inherit;
	color: rgba(0, 0, 0, 0.87);
	/* border-radius: 7px; */
}
.anchorInput:focus {
	outline: none;
}
.anchorInput:focus + .workshop-dropdown-options {
	/* display: block; */
}

.mui-2-dropdown-check-list-garage-select .anchorInput:after {
	position: absolute;
	content: "";
	border-left: 2px solid black;
	border-top: 2px solid black;
	padding: 5px;
	right: 10px;
	top: 20%;
	-moz-transform: rotate(-135deg);
	-ms-transform: rotate(-135deg);
	-o-transform: rotate(-135deg);
	-webkit-transform: rotate(-135deg);
	transform: rotate(-135deg);
}

.mui-2-dropdown-check-list-garage-select .anchorInput:active:after {
	right: 8px;
	top: 21%;
}

.mui-2-dropdown-check-list-garage-select .color-black-placeholder::placeholder {
	color: black;
}

.mui-2-dropdown-check-list-garage-select ul.workshop-dropdown-options {
	padding: 0;
	display: none;
	margin: 0;
	border: 1px solid #ccc;
	border-top: none;
	position: absolute;
	top: 50px;
	background: white;
	z-index: 9;
	max-height: 400px;
	overflow: auto;
	width: 300px;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	font-size: smaller;
    overflow-x: hidden;
}
.mui-2-dropdown-check-list-garage-select ul.workshop-dropdown-options li {
	list-style: none;
	width: 100%;
	display: inline-flex;
	padding: 0 0.4rem;
}

.mui-2-dropdown-check-list-garage-select ul.workshop-dropdown-options li > label {
	width: 100%;
	/* display: inline-block; */
	/* padding: 8px 0px; */
	cursor: pointer;
	font-size: 0.9rem !important;
}

.mui-2-dropdown-check-list-garage-select
	ul.workshop-dropdown-options
	li
	> label
	> input:checked
	+ span.margin_l_8 {
	color: green;
	font-weight: 600;
}

.mui-2-dropdown-check-list-garage-select
	ul.workshop-dropdown-options
	li
	> label
	> input:disabled {
	background: red;
	font-weight: 600;
}

.workshop-dropdown-options:hover {
	/* display: block; */
	/* display: block !important; */
}

.mui-2-dropdown-check-list-garage-select .selected-label {
	height: 15px;
	position: absolute;
	white-space: nowrap;
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	line-height: 1;
	letter-spacing: 0.00938em;
	top: 4px;
	left: 5px;
	font-size: 11px;
	color: rgba(0, 0, 0, 0.54);
	overflow-x: auto;
	width: 95%;
}

.mui-2-dropdown-check-list-garage-select .selected-label::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE and Edge */
.mui-2-dropdown-check-list-garage-select .selected-label {
	-ms-overflow-style: none;
}

.single-selected-item {
	color: green;
	font-weight: 600;
}

.multi-select-error {
	color: red;
	font-size: smaller;
	font-weight: 100;
}

.mui-2-dropdown-check-list-garage-select .input-box-error {
	border: 1px solid red;
	border-radius: 2px;
}

.color-black-label {
	color: black !important;
}

li:hover {
	background-color: #e5e5e5;
	color: black;
	font-weight: 600;
}

li {
	border-bottom: 1px solid #e5e5e5;
}

.mui-2-dropdown-check-list-garage-select ul.open {
	display: block;
}

.mui-2-dropdown-check-list-garage-select ul.close {
	display: none;
}

.cross-icon-search {
	position: absolute;
	right: 7px;
	top: 10px;
	padding: 3px;
	cursor: pointer;
}

.workshop-status-list{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    padding-left: 1.6rem;
    padding-right: 1.6rem;
    padding-top : 0.5rem;
    padding-bottom: 0.5rem; 
}

.workshop-status-list-v2-reset{
	padding-left: 1rem !important; 
	padding-top: 0 !important;
	padding-right: 0 !important;
	padding-bottom: 16px !important;
	border-bottom: 1px dashed #556282 !important;
}

.workshop-status-list .workshop-name{
    font-size: 1rem;
    font-weight: bold;
    font-stretch: normal;
    line-height: 1.43;
    letter-spacing: normal;
    text-align: left;
    color: #556282;
    position: relative;
}

.workshop-status-list .workshop-name::before{
    content: '';
    position: absolute;
    top: 0.4rem;
    left: -1rem;
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
}

.workshop-status-list .workshop-online::before{
    background-color: green;
}

.workshop-status-list .workshop-offline::before{
    background-color: #556282;
}


.workshop-status-list .workshop-details{
    font-size: 10px;
    font-weight: normal;
    font-stretch: normal;
    line-height: 1.2;
    letter-spacing: normal;
    text-align: left;
    color: rgba(0, 0, 0, 0.38);
    display: flex;
    justify-content: space-between;
    align-items: center;
}