@tailwind base;
@tailwind components;
@tailwind utilities;

body {
	margin: 0;
	padding: 0;
	/* font-family: Helvetica Neue, Helvetica, PingFang SC, Hiragino Sans GB,
		Microsoft YaHei, Arial, sans-serif; */
	font-family: sans-serif;
	/* font-size: 14px; */
	font-size: 1rem;
	color: hsl(223, 22%, 42%);
	background: hsl(240, 54%, 97%) !important;
	/* font-size: 80%; */
	/* background: #F8F9FE; */
}

body::-webkit-scrollbar{ 
	width : 6px; 
	height: 6px;
  }
  
body::-webkit-scrollbar-thumb{
	border-radius: 4px;
	background-color : rgb(0 0 0 / 15%);
  }
  

.makeHeading {
	font-weight: bold;
	font-size: 1rem;
}

.flex {
	display: flex;
}

.flex_1 {
	flex: 1;
}

.gap-1{
	gap: 0.5rem;
}

.gap-2{
	gap : 1rem;
}

.flexDirectionColumn {
	flex-direction: column;
}

.justifyContentSpaceBetween {
	justify-content: space-between;
}

.justifyContentFlexEnd {
	justify-content: flex-end;
}

.justifyContentSpaceEvenly {
	justify-content: space-evenly;
}

.justifyContentCenter {
	justify-content: center;
}

.justifySelfEnd {
	justify-self: end;
}

.alignItemsCenter {
	align-items: center;
}

.alignSelfCenter {
	align-self: center;
}

.width-15-Prcnt {
	width: 15%;
}

.width-20-Prcnt {
	width: 20%;
}

.width-25-Prcnt {
	width: 25%;
}

.width-40-Prcnt {
	width: 40%;
}

.width-50-Prcnt {
	width: 50%;
}

.width-60-Prcnt {
	width: 60%;
}

.width-70-Prcnt {
	width: 70%;
}

.width-95-Prcnt {
	width: 95%;
}

.componentPadding {
	padding: 6rem 2rem 2rem 14rem;
	/* padding: 8rem 2rem 2rem 15rem; */
}

.padding-1Rem {
	padding: 1rem;
}

.padding-2Rem {
	padding: 2rem;
}

.padding-5-Rem {
	padding: 5rem;
}

.paddingTop-8-Rem {
	padding-top: 8rem;
}

.paddingTop-4Rem {
	padding-top: 4rem;
}

.paddingBottom-5Rem {
	padding-bottom: 5rem !important;
}

.paddingBottom-6px {
	padding-bottom: 6px;
}

.padding-10px {
	padding: 10px;
}

.paddingLeft-15-Rem {
	padding-left: 15rem;
}

.paddingLeft-5Rem {
	padding-left: 5rem;
}

.paddingLeft-0 {
	padding-left: 0;
}

.paddingRight-5Rem {
	padding-right: 5rem;
}

.paddingRight-10Rem {
	padding-right: 10rem;
}

.marginTop-1rem {
	margin-top: 1rem;
}

.marginTop-2rem {
	margin-top: 2rem;
}

.marginTop-4rem {
	margin-top: 4rem;
}

.marginTop-5rem {
	margin-top: 5rem;
}

.marginBottom-point5rem {
	margin-bottom: 0.5rem;
}

.marginBottom-1rem {
	margin-bottom: 1rem;
}

.marginBottom-2rem {
	margin-bottom: 2rem;
}

.marginBottom-4rem {
	margin-bottom: 4rem;
}

.marginRight-point5rem {
	margin-right: 0.5rem;
}

.marginRight-2rem {
	margin-right: 2rem;
}

.marginLeftAuto {
	margin-left: auto;
}

.marginLeft-1rem {
	margin-left: 1rem;
}

.marginTopBottom-2rem {
	margin-top: 2rem;
	margin-bottom: 2rem;
}

.marginTopBottom-4rem {
	margin-top: 4rem;
	margin-bottom: 4rem;
}

.marginTopBottom-5px {
	margin: 5px 0;
}

.grid-1-2 {
	display: grid;
	grid-template-columns: repeat(2, 1fr);
	grid-gap: 3rem;
}

.grid-1-3 {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	grid-gap: 3rem;
}

.grid-1-4 {
	display: grid;
	grid-template-columns: repeat(4, 1fr);
	grid-gap: 3rem;
}

.gridArea-1-2 {
	grid-area: 1 / 2;
}

.grid-1-7 {
	display: grid;
	grid-template-columns: repeat(7, 1fr);
	grid-gap: 1rem;
}

.makeCard {
	background: white;
	padding: 2rem;
	border-radius: 10px;
	/* box-shadow: 0 2px 2px 0 #dfe1f3, 0 -2px 4px 0 #c6c7d7; */
	box-shadow: 0 2px 4px 0 rgba(169, 178, 200, 0.3);
}

.makeButton {
	padding: 0.5rem;
	border-radius: 5px;
	box-shadow: 0 2px 2px 0 #dfe1f3, 0 -2px 4px 0 #c6c7d7;
	width: fit-content;
	cursor: pointer;
}

.makeWhiteButton {
	background: white;
	color: hsl(211, 54%, 11%);
}

.makeFireFlyButton {
	background: hsl(211, 54%, 11%);
	color: white;
}

.makePickledBluewoodButton {
	background: rgb(48, 65, 86);
	color: white;
}

.makeBlackPearlButton {
	background: hsl(206, 73%, 10%);
	color: white;
}

.makeThunderBirdButton {
	background: hsl(3, 79%, 45%);
	color: white;
}

.makeFunGreenButton {
	background: hsl(140, 100%, 30%);
	color: white;
}

.makeBlueButton {
	background: #147ad6;
	color: white;
}

.makeOutlineButton {
	background: white;
	box-shadow: none;
	border: 1px solid black;
	color: black;
	padding: 0.5rem 1rem;
	border-radius: 3px;
	width: fit-content;
	cursor: pointer;
}

.athensGrayColor {
	color: #f4f4f5;
}

.cadetBlueColor {
	color: hsl(223, 22%, 72%);
}

.pickledBluewoodColor {
	color: rgb(48, 65, 86);
}

.blackPearlColor {
	color: #071c2c;
}

.woodBarkColor {
	color: hsl(3, 76%, 10%);
}

.shuttleGrayColor {
	color: #9b9b9b;
}

.selagoBackGround {
	background: #f8f9fe;
}

.pickledBluewoodBackGround {
	background: rgb(48, 65, 86);
}

.blackPearlBackGround {
	background-color: hsl(206, 73%, 10%);
}

.woodBarkBackGround {
	background: hsl(3, 76%, 10%);
}

.whiteBackGround {
	background: white;
}

.whiteLilacBackGround {
	background: hsl(240, 54%, 97%);
}

.catskillWhiteBorder {
	border: #ebeef5 1px solid;
}

.borderRadius-5-Px {
	border-radius: 5px;
}

.borderRadius-10-Px {
	border-radius: 10px;
}

.borderShadow {
	box-shadow: 0 2px 2px 0 #dfe1f3, 0 -2px 4px 0 #c6c7d7;
}

.textAlignCenter {
	text-align: center;
}

input[type="checkbox"] {
	width: 40px;
	height: 40px;
}

select {
	background: white;
	padding: 0.5rem;
	border-radius: 5px;
}

select:focus {
	outline: none;
}

.clickable:active {
	transform: translateY(4px);
}

.clickable:hover {
	background: hsl(206, 73%, 8%);
}

.clickable-white:active {
	transform: translateY(4px);
}

.clickable-white:hover {
	background: hsl(200, 2%, 76%);
}

.clickable-red:hover {
	background-color: tomato;
}

.clickable-red:active {
	transform: translateY(4px);
}

.link-small {
	color: #147ad6;
    font-size: 14px;
    text-decoration: underline;
}

.disabledButton-gray {
	background: gray !important;
    cursor: auto !important;
}

@media (max-width: 720px) {
	.componentPadding {
		padding: 10px;
	}

	.grid-1-3 {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-gap: 1rem;
	}
}

.hidden{
	display: none !important;
}

.distri-otp-header{
	font-size: 1.6rem;
	color: #1a1a1a;
	text-align: center;
	margin-top: 2rem;
}

.distri-otp-subheader{
   font: 1.2rem;
   color: #666666;
   opacity: 0.7;
   text-align: center;
   margin-top: 1rem;
}

.distri-otp-container{
	margin : 3rem 0;
	display: flex;
	justify-content: center;
}

.distri-otp-container .distri-otp-ui{
	display: flex;
	align-items: center;
	gap : 8px;
}

.distri-otp-container .distri-otp-ui .otp-box{
  width: 48px;
  height: 44px;
  flex-grow: 0;
  border-radius: 4px;
  border: solid 1px #e6e6e6;
  background-color: #fff;
  font-size: 1.6rem;
  text-align: center;
}

.distri-otp-btns{
	display: flex;
	justify-content: center;
	align-items: center;
	gap : 1.6rem;
	margin-top: 4rem;
}

.distri-otp-send-btn,
.distri-otp-submit-btn{
	border-radius: 4px;
	border: 0;
	width: 155px;
  	height: 40px;
	color: #fff;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;
}

.distri-otp-send-btn{
	background-color: rgba(0, 0, 0, 0.87);
	
}

.distri-otp-submit-btn{
	background-color: #3a9b14;
}

.distri-acc-filter{
 display: flex;
 flex-direction: column;
}

.distri-acc-filters{
	display: grid;
	grid-template-columns: 1fr 150px;
	gap: 8px;
}

.distri-acc-search-box{
  background-color: #fff;
  border : 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 4px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  cursor: pointer;
}

.distri-acc-search{
  font-size: 1rem;
  height: 40px;
  border : 0;
  flex-grow: 1;
  padding-left: 5px;
}

.distri-acc-search:focus{
	outline: none;
	border: 0;
}

.distri-icon{
	width: 20px;
}

.distri-acc-list{
	height: 150px;
	overflow-y: auto;
	background-color: #fff;
	z-index: 1;
	box-shadow: 0px 5px 3px 2px rgb(169 178 200 / 30%);
	display: flex;
	flex-direction: column;
	gap: 8px;
}

.distri-acc-list-item{
	display: flex;
	align-items: center;
	font-size: 14px;
	padding: 4px;
	box-sizing: border-box;
	cursor: pointer;
	min-height: 40px;
	max-width: calc(100% - 5px);
	border-bottom: 1px solid rgba(169, 178, 200, 0.3);
}
.distri-acc-list-item .truncate-text{
	display: inline-block;
	width: calc(100% - 70px);
	white-space: nowrap;
	text-overflow: ellipsis;
	overflow-x: hidden;
}

.distri-ps-container{
	display: flex;
	flex-direction: column;
	gap: 12px;
}

.distri-ps-header{
	font-size: 18px;
	color: #1a1a1a;
}

.distri-ps-inputs{
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap : 8px;
}

.distri-ps-btns{
	margin-top: 2rem;
	display: flex;
	gap: 16px;
}
.progress-container{
	height: 3px;
    width: 100%;
    background: #aaa;
    position: fixed;
    z-index: 16;
}

.progress-container .progress{
  height: 100%;
  width: 0;
  border-radius: 0.4rem;
  background: #ff4754;
  transition: width 0.4s ease;
}

#progress-overlay{
	display: none;
	position: fixed;
	z-index: 10000;
	width: 100%;
	height: 100vh;
	overflow: hidden;
	user-select: none;
	background-color: #4141441c;
}


#toast-container{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100vh;
	overflow: hidden;
	z-index: 1000000000000000000000000;
	display: none;
}

.active-toast{
	display: block !important;
}

.toast-elems{
	position: absolute;
	bottom: 50px;
	left: 50%;
	transform: translateX(-50%);
	width: 260px;
	/* height: calc(100% - 40px); */
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 10px;
	padding-top: 10px;
	padding-bottom: 10px;
}

#toast-container .show-toast-elem{
	width: 250px;
	min-height: 40px;
	box-shadow: 0 0 1px 1px rgb(233, 224, 224);
	padding : 0.5rem;
	box-sizing: border-box;
	border-radius: 4px;
	display: flex;
	align-items: center;
	gap: 8px;
	color: #fff;	
	transition: transform 750ms linear;
}

#toast-container .toast-elem-info{
	background-color: #65adec;
}

#toast-container .toast-elem-success{
	background-color: hsl(140, 100%, 30%);
}

#toast-container .toast-elem-error{
	background-color: hsl(3, 79%, 45%);

}


.show-toast-elem > i {
	padding: 1rem;
}

.toast-body{
	display: flex;
	flex-direction: column;
	gap: 4px;
}

.toast-body .toast-title{
   font-weight: 600;
   font-size: 1rem;
   text-transform: capitalize;
}

.toast-body .toast-message{
  font-weight: 500;
  font-size: 0.875rem;
  padding-bottom: 4px;
}

.distri-delete-msg{
	font-size: 1.1em;
	color: rgba(0, 0, 0, 0.87);
	opacity: 0.7;
	text-align: center;
}

.popup-fade-in{
	opacity: 1;
	/* transform: translateY(0); */
}

.popup-fade-out{
	opacity: 0;
	/* transform: translateY(20px); */
}

.overlay-delete-popup{
	z-index: 198;
}

.distri-transaction-history{
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
}

.distri-add-btn, .distri-btn{
border : 0;
border-radius : 4px;
padding : 1rem 2rem;
font-size: 1rem;
cursor : pointer;
color : #fff;
background-color : hsl(211, 54%, 11%);
opacity : 1;
transform : scale(1);
transition : all 250ms linear;
}

.distri-btn{
	padding : 0.7rem 1rem;
	white-space: nowrap;
	font-size: 12px;
	text-align: center;
}

.distri-add-btn:active, .distri-btn:active{
	opacity : 0.4;
	transform : scale(0.95);
}

.distri-pay-container, .distri-layout{
	padding: 6rem 2rem 2rem 14rem;
}

.distri-pay-search{
	display: flex;
	align-items: center;
	gap : 8px;
}

.distri-pay-filters{
	display: grid;
	grid-template-columns: 2fr repeat(3, 1fr);
	gap: 8px;
	margin : 1rem auto;
}

.distri-pay-filters .distri-pay-date-filter{
	display: flex;
	align-items: center;
	gap: 8px;
}

.distri-pay-filters .distri-pay-date-filter > div{
	flex-grow: 1;
	flex-shrink: 1;
}



.distri-recon-container{
    padding: 0.875rem 1.4rem;
}

.distri-recon-header{
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 0.875rem;
}

.distri-recon-header .malaysia-recon-item{
    font-weight: bold;
    color: rgba(0, 0, 0, 0.87);
}

.distri-recon-body{
    display: flex;
    flex-direction: column;
    margin: 1rem 2rem;
}

.distri-recon-fund-account{
    display: flex;
    flex-direction: column;
}

.d-fund-account-detail{
    display: flex;
	justify-content: start;
	width: 80%;
    margin: 4px auto;
}

.distri-recon-account-detail{
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    font-weight: 500;
    gap: 8px;
}

.distri-recon-payment-details{
    display: flex;
    flex-direction: column;
}

.distri-payment{
	display: flex;
	flex-direction: column;
	align-items: center;
}

.distri-payment .distri-recon-payment-item{
    display: flex;
    gap: 0.5rem;
    padding-bottom : 10px;
    /* padding-left: 8rem; */
    color: rgba(0, 0, 0, 0.87);
}

.distri-payment .distri-recon-payment-item > div:first-child{
    width: 150px;
}


.distri-recon-payment-options{
    display: flex;
    flex-direction: row;
	align-items: center;
	flex-wrap: wrap;
	max-width: 240px;
    gap: 0.5rem;
}

.distri-recon-btns{
    display: flex;
    justify-content: center;
    align-items: center;
    gap : 3rem;
    margin-top: 1.6rem;
}

.distri-recon-btns > button{
    padding: 0.5rem 2rem;
    font-size: 1rem;
    border-radius: 4px;
    cursor: pointer;
    opacity: 1;
    transition: all 250ms linear;
}

.distri-recon-btns > button:first-child{
    background-color: #fff;
    color: rgba(0, 0, 0, 0.87);
    border: 1px solid rgba(0, 0, 0, 0.87);
}

.distri-recon-btns > button:last-child{
    color: #fff;
    background-color: #147ad6;
    border: 1px solid #147ad6;
    
}

.distri-recon-btns > button:active{
    opacity: 0;
}

.distri-schedule-payout{
	display: flex;
	align-items: center;
	gap: 8px;
	margin-top: 4px;
}

.distri-add-account-head{
	margin: 1rem 0;
	display: flex;
	justify-content: space-between;
	align-items: center;
}


.add-account-btn{
	padding: 0.5rem 1rem;
    font-size: 1rem;
    border-radius: 4px;
    cursor: pointer;
    opacity: 1;
	transform: scale(1);
    transition: all 350ms linear;
	color: #fff;
    background-color: #147ad6;
    border: 1px solid #147ad6;
}

.add-account-btn:active{
    opacity: 0.4;
	transform: scale(1.1);
}

.distri-add-accounts-heading{
	color: rgba(0, 0, 0, 0.87);
	border-bottom: 1px solid rgba(0, 0, 0, 0.2);
	padding-bottom: 6px;
	margin: 0;
	font-size: 16px;
}

.distri-add-accounts-wrapper{
	display: grid;
	grid-template-columns: 250px auto;
	gap: 8px;
	margin-top: 8px;
	padding-right: 16px;
}

.distri-add-accounts-indicator{
	display: flex;
	flex-direction: column;
}

.distri-add-accounts-indicator ul{
	list-style: decimal;
	display: flex;
	flex-direction: column;
	gap: 4rem;
}

.distri-add-accounts-indicator ul li{
	border-bottom: 0;
	font-size: 0.875rem;
	background: none !important;
	user-select: none !important;
}

.distri-add-accounts-indicator ul li:hover{
	background: none !important;
}

.distri-add-accounts-indicator ul li:first-child{
	position: relative;
}

.distri-add-accounts-indicator ul li:first-child::after{
	content: " ";
	display: inline-block;
	height: 3.5rem;
	width: 1px;
	background-color: rgba(0, 0, 0, 0.2);
	position: absolute;
	top: 25px;
	left: 50px;
}

.distri-add-acounts-form{
	display: flex;
	flex-direction: column;
	gap: 8px;
	margin-top: 8px;
}

.distri-add-acounts-form h3{
	letter-spacing: 0.5px;
	font-size: 14px;
	text-transform: uppercase;
	color: #666666;
}

.distri-add-acounts-form form{
	display: flex;
	flex-direction: column;
	margin-top : 0.5rem;
	gap: 10px;
}

.distri-add-acounts-form >  form > div{
	display: flex;
	flex-direction: column;
	gap: 5px;
	max-width: 350px; 
}

.distri-add-acounts-form > form > div > label{
	font-size: 12px;
	color: #1a1a1a;
}

.distri-add-acounts-form > form  > div  > input{
	height: 1.8rem !important;
	font-size: 14px !important;
	padding-left: 8px !important;
	border : 1px solid #9b9b9b;
	border-radius: 2px !important;
}

.distri-add-acounts-form > form > div > select{
	height: 2.4rem !important;
	font-size: 14px !important;
	padding-left: 8px !important;
	border : 1px solid #9b9b9b;
	border-radius: 2px !important;
}

.distri-add-acounts-form > form > div > input:focus{
	outline: none;
	border: 1px solid #65adec;
}

.distri-add-accounts-btns{
	grid-column-start: 1;
	display: flex;
	align-items: center;
	gap: 16px;
	margin-top: 12px;
}

.distri-add-accounts-btns button{
	padding: 0.7rem 2rem;
	color: #fff;
	background-color: #147ad6;
	border: 0;
	border-radius: 4px;
	cursor: pointer;
}

button{
	max-height: 4rem;
	text-align: center;
	opacity: 1;
	transform: scale(1);
	transition: all 750ms linear;
}

button:active{
	opacity: 0.5;
	transform: scale(1.1);
}

.distri-radio-btns{
	display: flex;
	flex-direction: row !important;
	align-items: center;
	gap: 8px;
}

.distributor-selects{
	display: flex;
	align-items: center;
	gap: 8px;
}

.popup-loader{
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	z-index: 10000;
}

.distri-cancel-icon{
	display: flex;
	justify-content: end;
	cursor: pointer;
}

.distri-cancel-icon:active{
	color: red;
}

.nn-process-settlement-container{
	padding : 1rem;
	max-height: 400px;
	left: 50% !important;
	top: 20px !important;
	transform: translateX(-50%) !important;
}

.nn-opening-balance-container{
	padding : 1rem;
	max-width: 300px;
	max-height: 280px;
	display: flex;
	flex-direction: column;
	left: 50% !important;
	top: 20px !important;
	transform: translateX(-50%) !important;
}

.w_button{
	border: 0;
    outline: 0;
    color: #fff;
    background-color: hsl(206, 73%, 20%);
    white-space: nowrap;
    border-radius: 5px;
    padding: 12px 18px;
    cursor: pointer;
}

.input-spin{
	display: flex;
	flex-direction: column;
	gap: 3px;
	font-size: 10px;
}

.input-spin > *{
	cursor: pointer;
	opacity: 1;
	padding: 1px 4px;
	border-radius: 4px;
}

.input-spin > *:active{
	opacity: 0.6;
	background-color: rgb(222, 218, 218);
}

.sp-item-list{
	display: grid;
	grid-template-columns: 40px auto 150px;
	gap: 6px;
	flex-grow: 1;
}

.sp-item-list img{
	width: 40px;
}

.sp-item-data{
	display: flex;
	flex-direction: column;
	gap: 4px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: normal;
	text-align: left;
	color: rgba(0, 0, 0, 0.87);
	margin-top: 4px;
}

.sp-sku-code{
	color: white;
	padding: 4px 8px;
  border-radius: 100px;
  background-color: #065fd4;
  font-size: 10px;
  margin: auto;
}

.sp-item-data div:first-child{
	font-size: 12px;
	font-weight: 500;
}

/* .sp-item-data div:last-child{
	font-size: 10px;
} */

.sp-item-data div:last-child .desc{
	color: rgba(0, 0, 0, 0.54);
}


/* button classes */

.btn{
    color: #fff;
    padding: 10px 26px 10px 27px;
    border-radius: 4px;
    border: 0;
    cursor: pointer;
    white-space: nowrap;
    opacity: 1;
    display: flex;
	justify-content: center;
	align-items: center;
    gap: 4px;
}

.btn:disabled{
    opacity: 0.7;
    cursor: not-allowed;
}

.btn--success-variant{
    background-color: #3a9b14;

}

.btn--primary-variant{
    background-color: #3a6dc2;
    /* cursor: not-allowed; */
}

.btn--error-variant{
    background-color: #e73c33;
    /* cursor: not-allowed; */
}

.icon--xs > svg{
	width: 30px;
	height: 30px;
}

.icon--sm > svg{
	width : 80px;
	height: auto;
}

.icon--md > svg{
	width: 100px;
	height: auto;
}

.icon--lg > svg{
	width: 180px;
	height: auto;
}


.scroll::-webkit-scrollbar{ 
	width : 6px; 
	height: 6px;
  }
  
.scroll::-webkit-scrollbar-thumb{
	border-radius: 4px;
	background-color : rgb(0 0 0 / 15%);
  }

#progress-spinner{
	position: fixed;
	inset: 0;
	z-index: 100000;
	display: grid;
	place-items: center;
	background-color: #a8bbdd79;
}

.mtd-metrics{
	display: grid;
	grid-template-columns: repeat(3, 200px);
	grid-template-rows: 86px;
	place-items: center;
	border-radius: 6px;
	background-color: #fffdee;
	max-width: 600px;
	margin-bottom: 10px;
}

.mtd-metrics .mtd-metric{
	display: flex;
	flex-direction: column;
	align-items: center;
	gap: 20px;
	position: relative;
	/* border-right: 1px solid #d6d6d6; */
}

.mtd-metrics .mtd-metric:not(:last-child)::after{
	content: " ";
	position: absolute;
	right: -40px;
	top: 50%;
	transform: translateY(-50%);
	display: inline-block;
	width: 1px;
	height: 60px;
	background-color: #d6d6d6;
}

.rs-dropdown-toggle, .rs-table-body-wheel-area{
	background-color: #fff !important;
}

.rs-table-cell-header > .rs-table-cell{
	color: #fff !important;
	background-color:#071c2c !important;
}

.otp-ui{
	display: flex;
	align-items: center;
	gap : 8px;
}

.otp-ui .otp-box{
  width: 5px;
  height: 40px;
  flex-grow: 0;
  border-radius: 4px;
  border: solid 1px #e6e6e6;
  background-color: #fff;
  font-size: 1.6rem;
  text-align: center;
}

.plordes-modal{
  width: 934px !important;
  max-height: 450px !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: flex-start !important;
  margin: 78px 253px 0 69px !important;
  padding: 16px 32px 32px !important;
  border-radius: 16px !important;
  box-shadow: 0 11px 15px 0 rgba(0, 0, 0, 0.2), 0 9px 46px 0 rgba(0, 0, 0, 0.12), 0 24px 38px 0 rgba(0, 0, 0, 0.14) !important;
  background-color: #fff !important;
}