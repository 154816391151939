.header-container {
	padding: 1rem 0;
	/* padding: 0.5rem; */
	display: flex;
	align-items: center;
	position: fixed;
	width: 100%;
	z-index: 10;
	background: hsl(240, 54%, 97%);
	/* background: white; */
	/* box-shadow: 0 5px 5px -5px #333; */
}

.header {
	display: flex;
	margin-left: 14rem;
	/* width: 20%; */
	width: 100%;
	justify-content: space-between;
	/* color: hsl(210, 100%, 35%); */
	font-size: 1rem;
	font-weight: bold;
	/* color: #E73C33; */
}

.header-element {
	display: flex;
	align-items: center;
	justify-content: space-around;
	/* padding: 0.5rem; */
	/* width: 48%; */
	width: 100%;
}

/* .header-element:hover {
    cursor: pointer;
    background:  #1a53ff;
    border-radius: 5px;
    color: white;
} */

.hamburger-menu {
	width: 40px;
	height: 3rem;
	display: flex;
	flex-flow: column;
	justify-content: space-around;
	align-items: center;
	padding: 10px 0;
	box-sizing: border-box;
	cursor: pointer;
}

.hamburger-menu div {
	width: 90%;
	height: 3px;
	background-color: white;
}

a {
	text-decoration: none;
}

.location {
	margin-left: 1rem;
	color: #071c2c;
	font-size: 1.4rem;
}

.log-out {
	display: flex;
	align-items: center;
	cursor: pointer;
}

.log-out-icon {
	margin-right: 2rem;
	/* font-size: 32px; */
}

.log-out-text {
	margin-right: 1rem;
	/* box-shadow: 0 2px 2px 0 #dfe1f3, 0 -2px 4px 0 #c6c7d7;
    padding: 0.5rem;
    border-radius: 5px;
    position: absolute;
    right: 1rem;
    top: 3.8rem;
    background: white;
    display: none; */
}

.log-out-icon:hover + .log-out-text {
	display: block;
}

@media only screen and (max-width: 600px) {
	.header-container {
		background-color: #071c2c;
		padding: 1rem 0;
		display: flex;
		align-items: center;
		z-index: 10;
		color: white;
		position: inherit;
	}

	.header {
		margin-left: 0px;
	}

	.header-element {
		width: 80%;
	}

	.log-out-icon {
		display: none;
	}
}

@media (min-width: 450px) {
	.hamburger-menu {
		display: none;
	}
}
