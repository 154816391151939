.carDetails {
    display: flex;
    justify-content: space-between;
    width: 98%;
    margin-bottom: 15px;
}

.carDetailsSpan {
    display: flex;
    align-items: center;
}

.carDetailsHeading {
    color: #0d1c2c;
    font-size: 14px;
}

.carDetailsSubHeading {
    font-size: 12px;
    padding-top: 2px;
}

.partsDetails {
    display: flex;
    justify-content: space-between;
    width: 50%;
    margin-top: 20px;
}

.flexForButtons {
    display: flex;
    justify-content: space-between;
    /* width: 45%; */
}

.whiteButtons {
    display: flex;
    justify-content: space-between;
}

.backOrder {
    display: flex;
    align-items: center;
    height: 55px;
    justify-content: space-around;
    width: 150px;
    cursor: pointer;
}

.universal-edit {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 91%;
}

.d-flex{
    display: flex;
    flex-direction: row-reverse;
}
.warehouse{
    margin-right: 25px;
}