.nn-return-container{
    position: relative;
    top: 6rem;
    margin-left: 14rem;
    margin-right: 2rem;
    margin-bottom: 8rem;
    box-sizing: border-box;
}

.nn-input-container{
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 0.5rem;
}

.nn-input-container > .nn-return-input{
    width: 16rem;
    height: 2.8rem;
    padding-left: 0.5rem;
    font-weight: 500;
    font-size: 1rem;
    border-width: 1px;
    border-radius: 2px;
    border-color: rgba(0, 0, 0, 0.2);
}

.nn-input-container > .nn-return-btn{
    padding: 0.8rem 2rem;
    background-color: #fff;
    border-width: 1px;
    border-color: rgba(0, 0, 0, 0.35);
    cursor: pointer;
    font-weight: 500;
    font-size: 1rem;
    opacity: 1;
}

.nn-input-container > .nn-return-btn:active{
    opacity: 0.7;
}

.nn-return-input:focus{
    outline: none;
}

.nn-buttons{
    text-align: end;
}

.nn-return-btn-green{
    padding: 0.7rem 1.5rem;
    color: #fff;
    background-color: hsl(140, 100%, 30%);
    border: 0;
    border-radius: 3px;
    cursor: pointer;
    opacity: 1;
}

.nn-return-btn-green:active{
    opacity: 0.7;
}

.spacing-y-md{
    margin: 1rem 0;
}

.space-v-1{
    display: flex;
    gap : 0.5rem;
    align-items: center;
}

