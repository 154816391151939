.width-20-Prcnt {
    width: 20%
}

.overlayDiv {
    width: 100vw;
    height: 140vh;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    /* background: red; */
}

.datepickerContainer{
    height: 42px !important;
    width: 130px !important;
	box-sizing: border-box !important;
    border-radius: 5px !important;   
}