.loader {
	border: 4px solid #f3f3f3;
	border-radius: 50%;
	border-top: 4px solid #3498db;
	width: 40px;
	height: 40px;
	-webkit-animation: spin 1s linear infinite; /* Safari */
	animation: spin 1s linear infinite;
}

.loader--sm {
	border: 3px solid #f3f3f3;
	border-radius: 50%;
	border-top: 3px solid #3498db;
	width: 13px;
	height: 13px;
	-webkit-animation: spin 1s linear infinite; /* Safari */
	animation: spin 1s linear infinite;
}

.image-loader {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
}

.progressive-loader {
	display: inline-block;
	position: relative;
	width: 80px;
	height: 80px;
}

.progressive-loader div {
	display: inline-block;
	position: absolute;
	left: 8px;
	width: 16px;
	background: #5372b5;
	animation: progressive-loader 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}

.progressive-loader div:nth-child(1) {
	left: 8px;
	animation-delay: -0.24s;
}

.progressive-loader div:nth-child(2) {
	left: 32px;
	animation-delay: -0.12s;
}

.progressive-loader div:nth-child(3) {
	left: 56px;
	animation-delay: 0;
}

@keyframes progressive-loader {
	0% {
		top: 8px;
		height: 64px;
	}
	50%,
	100% {
		top: 24px;
		height: 32px;
	}
}

/* Safari */
@-webkit-keyframes spin {
	0% {
		-webkit-transform: rotate(0deg);
	}
	100% {
		-webkit-transform: rotate(360deg);
	}
}

@keyframes spin {
	0% {
		transform: rotate(0deg);
	}
	100% {
		transform: rotate(360deg);
	}
}

/*  */
.new-loader {
	/* position: absolute;
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 50px;
	height: 10px;
	background: #3498db;
	border-radius: 5px;
	animation: load 1.1s ease-in-out infinite; */
	width: 90px;
	height: 10px;
	margin-bottom: 2rem;
	background: #5372b5;
	border-radius: 5px;
	animation: load 0.7s ease-in-out infinite;
}
/* .new-loader:before,
.new-loader:after {
	position: absolute;
	display: block;
	content: "";
	animation: load 1.1s ease-in-out infinite;
	height: 10px;
	border-radius: 5px;
}
.new-loader:before {
	top: -20px;
	left: 10px;
	width: 40px;
	background: #ef4836;
}
.new-loader:after {
	bottom: -20px;
	width: 35px;
	background: #f5ab35;
} */
@keyframes load {
	0% {
		transform: translateX(40px);
	}
	50% {
		transform: translateX(-30px);
	}
	100% {
		transform: translateX(40px);
	}
}
