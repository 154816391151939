.error_bound_wrap{
background-color: rgb(216, 214, 214);
display: flex;
flex-direction: column;
min-height: 100vh;
box-sizing: border-box;
position: relative;
}

.error_bound_wrap .error_bound_card{
    width: min(80%, 300px);
    background-color: #fff;
    padding: 1rem;
    border-radius: 6px;
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    line-height: 28px;
}

.error_bound_card .error_bound_title{
    color: rgb(238, 95, 95);
    font-weight: 600;
}

.error_bound_card .error_bound_message{
    font-size: 0.875rem;
}

.error_bound_card .error_bound_reload{
    text-align: right;
    font-weight: 600;
    margin-top: 12px;
}

.error_bound_reload button{
    padding: 0.5rem 1rem;
    border-radius: 5px;
    color: #fff;
    background-color: rgb(243, 79, 79);
    border: 0;
    cursor: pointer;
}