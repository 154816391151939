.mui-2-dropdown-check-list {
	height: 48px;
	border-radius: 7px;
	border: solid 1px #d0d0d0;
	background-color: white;
	/* background-color: rgba(255, 255, 255, 0.36); */
	position: relative;
	display: flex;
	/* border-radius: 10px; */
}

.mui-2-dropdown-check-list .anchorInput {
	padding-left: 5px;
	border: none;
	width: 100%;
	background: transparent;
	font-size: 16px;
	font-family: inherit;
	color: rgba(0, 0, 0, 0.87);
	/* border-radius: 7px; */
}
.anchorInput:focus {
	outline: none;
}
.anchorInput:focus + .mui-2-dropdown-options {
	/* display: block; */
}

.mui-2-dropdown-check-list .anchorInput:after {
	position: absolute;
	content: "";
	border-left: 2px solid black;
	border-top: 2px solid black;
	padding: 5px;
	right: 10px;
	top: 20%;
	-moz-transform: rotate(-135deg);
	-ms-transform: rotate(-135deg);
	-o-transform: rotate(-135deg);
	-webkit-transform: rotate(-135deg);
	transform: rotate(-135deg);
}

.mui-2-dropdown-check-list .anchorInput:active:after {
	right: 8px;
	top: 21%;
}

.mui-2-dropdown-check-list .color-black-placeholder::placeholder {
	color: black;
}

.mui-2-dropdown-check-list ul.mui-2-dropdown-options {
	padding: 2px;
	display: none;
	margin: 0;
	border: 1px solid #ccc;
	border-top: none;
	position: absolute;
	top: 50px;
	background: white;
	z-index: 9;
	max-height: 350px;
	overflow: auto;
	width: 100%;
	box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
	font-size: smaller;
}
.mui-2-dropdown-check-list ul.mui-2-dropdown-options li {
	list-style: none;
	width: 100%;
	display: inline-flex;
	padding: 0;
}

.mui-2-dropdown-check-list ul.mui-2-dropdown-options li > label {
	width: 100%;
	display: inline-block;
	/* padding: 8px 0px; */
	cursor: pointer;
	padding: 8px;
	font-size: 0.9rem !important;
}

.mui-2-dropdown-check-list
	ul.mui-2-dropdown-options
	li
	> label
	> input:checked
	+ span.margin_l_8 {
	color: green;
	font-weight: 600;
}

.mui-2-dropdown-check-list
	ul.mui-2-dropdown-options
	li
	> label
	> input:disabled {
	background: red;
	font-weight: 600;
}

.mui-2-dropdown-options:hover {
	/* display: block; */
	/* display: block !important; */
}

.mui-2-dropdown-check-list .selected-label {
	height: 15px;
	position: absolute;
	white-space: nowrap;
	font-family: "Roboto", "Helvetica", "Arial", sans-serif;
	line-height: 1;
	letter-spacing: 0.00938em;
	top: 4px;
	left: 5px;
	font-size: 11px;
	color: rgba(0, 0, 0, 0.54);
	overflow-x: auto;
	width: 95%;
}

.mui-2-dropdown-check-list .selected-label::-webkit-scrollbar {
	display: none;
}

/* Hide scrollbar for IE and Edge */
.mui-2-dropdown-check-list .selected-label {
	-ms-overflow-style: none;
}

.single-selected-item {
	color: green;
	font-weight: 600;
}

.multi-select-error {
	color: red;
	font-size: smaller;
	font-weight: 100;
}

.mui-2-dropdown-check-list .input-box-error {
	border: 1px solid red;
	border-radius: 2px;
}

.color-black-label {
	color: black !important;
}

li:hover {
	background-color: #e5e5e5;
	color: black;
	font-weight: 600;
}

li {
	border-bottom: 1px solid #e5e5e5;
}

.mui-2-dropdown-check-list ul.open {
	display: block;
}

.mui-2-dropdown-check-list ul.close {
	display: none;
}

.cross-icon-search {
	position: absolute;
	right: 7px;
	top: 10px;
	padding: 3px;
	cursor: pointer;
}
