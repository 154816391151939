.calendar-container {
	border: 1px gray solid;
	position: absolute;
	/* top: 12rem; */
	padding: 1rem;
	padding-bottom: 0.5rem;
	z-index: 5;
	background: white;
}

.border-none {
	border: none;
}

.margin-top-1rem {
	margin-top: 1rem;
}

.margin-bottom-1rem {
	margin-bottom: 1rem;
}

.react-calendar__navigation__prev2-button,
.react-calendar__navigation__next2-button {
	display: none;
}

.react-calendar__navigation__prev-button {
	order: 1;
	border-radius: 100%;
}

.react-calendar__navigation__next-button {
	order: 2;
	border-radius: 100%;
}

.react-calendar__navigation__label {
	text-align: left;
	font-weight: bold;
}

abbr {
	text-decoration: none;
	color: black;
}

.text-align-center {
	text-align: center;
}

.apply-button {
	width: 149px;
	height: 48px;
	border-radius: 10px;
	background-color: #6a82f5;
	color: white;
	border: none;
	margin-top: 1.2rem;
	margin-right: 0.5rem;
}

.bottom {
	height: 80px;
	background-color: #f5f4f7;
	text-align: right;
	margin-top: 1rem;
}

.overlayDiv {
	width: 100%;
	height: 280vh;
	position: absolute;
	/* top: 0;
    left: 0; */
	z-index: 1;
	/* background: gray; */
}

@media screen and (max-width: 600px) {
	.calendar-container {
		width: 85%;
	}
}
